<!--
 * @Descripttion: 新保
 * @version: 1.0
 * @Author: 魏鑫
 * @Date: 2019-12-24 14:38:31
 * @LastEditors: 张天鹏
 * @LastEditTime: 2020-06-05 10:12:19
 -->
<template>
  <div class="mainbody">
    <div>
      <div class="selectInsurance">
        <div class="longRisk" :class="isActive==='C'?'isActive':''" @click="selectRisk('long')">长险</div>
        <div class="shortRisk" :class="isActive==='D'?'isActive':''" @click="selectRisk('short')">短险</div>
      </div>
      <div v-if="isActive==='C'">
        <md-tab-bar
          v-model="current"
          class="tabBar"
          :items="items"
          @change="onTabChange"
          style="width:100%"
        />
        <div class="list" :style="{'height': (reportList.length ? '85vh' :'100vh')}">
          <md-scroll-view
            v-show="!isNull"
            ref="scrollView"
            auto-reflow
            :scrolling-x="false"
            @end-reached="loadMore"
          >
            <div class="product" v-for="(item,index) of reportList" :key="index">
              <router-link :to="{path: '/xqShow',query: { policyno: item.policyno }}" tag="div">
                <div class="product-lf">
                  <div class="product-rg-top">
                    <span class="p-name">{{item.prodname}}</span>
                    <span class="xq-ymont" v-if="item.renewalStatus===1||item.renewalStatus===2">待续期</span>
                    <span class="xq-ymont" v-else-if="item.renewalStatus===3">已续期</span>
                    <span class="xq-ymont" v-else="item.renewalStatus===4">已过期</span>
                  </div>
                  <div class="product-bom">
                    <div class="product-rg-bom">
                      <span class="tb-name">投 / 被保人</span>
                      <span class="paramValue">{{item.tbname}} / {{item.bbname}}</span>
                    </div>
                    <div class="product-rg-bom">
                      <span class="sx-rq">保障时间</span>
                      <span class="paramValue">{{item.sxdate}}~{{item.endbzyears}}</span>
                    </div>
                    <div class="product-rg-bom">
                      <span class="sx-rq">保费</span>
                      <span class="paramValue">{{item.ymount}}元</span>
                    </div>
                    <div class="product-rg-bom">
                      <span class="xq-qc">续期期次</span>
                      <span class="paramValue">第{{item.years}}次</span>
                    </div>
                  </div>
                  <div
                    class="renewalSituation"
                    v-if="item.renewalStatus===1||item.renewalStatus===2"
                  >
                    <div class="product-rg-bom">
                      <div
                        class="renewalEndTime"
                        :class="item.isGrace==='0'?'noBottom':''"
                      >续保结束时间：{{ item.offDate }}</div>
                    </div>
                    <div
                      class="product-rg-bom"
                      style="text-align:center;"
                      v-if="item.isGrace==='0'"
                    >
                      <div class="renewalEndTime">此保单已进入宽限期，请及时跟进！</div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <md-scroll-view-more slot="more" :is-finished="loading" />
          </md-scroll-view>
          <div class="nodata" v-show="isNull">
            <img class="kongimg" src="@/assets/abd/image/null.png" alt />
            <p class="asdasdqwe">目前还没有内容哦~</p>
          </div>
        </div>
        <!-- <md-result-page
          :img-url="require('@/assets/abd/image/null.png')"
          v-else
          subtext="要不然刷新试试？"
        />-->
      </div>
      <div v-if="isActive==='D'">
        <md-tab-bar
          v-model="shortCurrent"
          class="tabBar"
          :items="shortItems"
          @change="onTabChange"
          style="width:100%"
        />
        <div class="list" :style="{'height': (reportList.length ? '85vh' :'100vh')}">
          <md-scroll-view
            v-show="!isNull"
            ref="scrollView"
            auto-reflow
            :scrolling-x="false"
            @end-reached="loadMore"
          >
            <div class="product" v-for="(item,index) of reportList" :key="index">
              <router-link :to="{path: '/xqShow',query: { policyno: item.policyno }}" tag="div">
                <div class="product-lf">
                  <div class="product-rg-top">
                    <span class="p-name">{{item.prodname}}</span>
                    <span class="xq-ymont" v-if="item.renewalStatus===1||item.renewalStatus===2">待续保</span>
                    <span class="xq-ymont" v-else-if="item.renewalStatus===3">已续保</span>
                    <span class="xq-ymont" v-else="item.renewalStatus===4">已过期</span>
                  </div>
                  <div class="product-bom">
                    <div class="product-rg-bom">
                      <span class="tb-name">投/被保人</span>
                      <span class="paramValue">{{item.tbname}}/{{item.bbname}}</span>
                    </div>
                    <div class="product-rg-bom">
                      <span class="sx-rq">保障时间</span>
                      <span class="paramValue">{{item.sxdate}}~{{item.endbzyears}}</span>
                    </div>
                    <div class="product-rg-bom">
                      <span class="sx-rq">保费</span>
                      <span class="paramValue">{{item.ymount}}元</span>
                    </div>
                  </div>
                  <div class="renewalSituation" v-if="item.renewalStatus===1">
                    <div class="product-rg-bom" style="margin-top: 0.2rem;">
                      <div class="renewalEndTime">续保结束时间：{{ item.offDate }}</div>
                      <!-- <div class="forwarding" @click="forwardBtn">转发</div> -->
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <md-scroll-view-more slot="more" :is-finished="loading" />
          </md-scroll-view>
          <div class="nodata" v-show="isNull">
            <img class="kongimg" src="@/assets/abd/image/null.png" alt />
            <p class="asdasdqwe">目前还没有内容哦~</p>
          </div>
        </div>
        <!-- <md-result-page
          :img-url="require('@/assets/abd/image/null.png')"
          v-else
          subtext="要不然刷新试试？"
        />-->
      </div>
    </div>
    <md-popup v-model="isPopupShow" :mask-closable="closable">
      <div class="icdoling">
        <md-icon name="spinner" color="blue" size="lg" style="-webkit-filter:invert(1)"></md-icon>
      </div>
    </md-popup>
  </div>
</template>
<script>
import { xqListShow } from "@/api/hx/renewal/index";
import { getStorage } from "@/lib/util";
import loadMore from "@/mixins/loadmore";
export default {
  mixins: [loadMore],
  data() {
    return {
      closable: false,
      isPopupShow: true,
      // Tbname: "",
      pageNo: 1,
      loading: false,
      reportList: [],
      totalpage: 0,
      height: 0,
      current: 1,
      isNull: false,
      shortCurrent: 1,
      isActive: "C",
      renewalStatus: 1,
      items: [
        { name: 1, label: "待续期" },
        { name: 3, label: "已续期" },
        { name: 4, label: "已过期" }
      ],
      shortItems: [
        { name: 1, label: "待续保" },
        { name: 3, label: "已续保" },
        { name: 4, label: "已过期" }
      ]
    };
  },
  beforeRouteEnter(yo, from, next) {
    next(vm => {});
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
  created() {
    // this.$nextTick(() => {
    //   setTimeout(() => {
    //     this.height =
    //       window.innerHeight -
    //       document.getElementsByClassName("search")[0].clientHeight -
    //       15;
    //   }, 500);
    // });
  },
  mounted() {
    if (sessionStorage.getItem("sessionData")) {
      let sessionData = JSON.parse(sessionStorage.getItem("sessionData"));
      this.isActive = sessionData.isActive;
      if (this.isActive === "C") {
        this.current = sessionData.renewalStatus;
      }
      if (this.isActive === "D") {
        this.shortCurrent = sessionData.renewalStatus;
      }
      this.renewalStatus = sessionData.renewalStatus;
    }
    this.getData();
  },
  methods: {
    async getData(isInit = true) {
      this.isPopupShow = true;
      let { pageNo } = this;
      if (!isInit) {
        this.totalpage <= pageNo
          ? (this.pageNo = this.totalpage)
          : (this.pageNo = ++pageNo);
        this.$refs.scrollView.finishLoadMore();
      }
      let data = {
        pageNo: pageNo,
        size: 10,
        empno: getStorage("u_s", {}).empno,
        comid: getStorage("u_s", {}).comid,
        renewalStatus: this.renewalStatus,
        isshort: this.isActive
      };
      let res = await xqListShow(data);
      this.total = res.data.data.total;
      this.totalpage = res.data.data.pages;
      pageNo > 1
        ? res.data.data.records.map(item => this.reportList.push(item))
        : (this.reportList = res.data.data.records);
      if (this.reportList.length == 0) {
        this.isNull = true;
      } else {
        this.isNull = false;
      }
      // eslint-disable-next-line no-unused-expressions
      this.pageNo >= this.totalpage ? (this.loading = true) : "";
      this.isPopupShow = false;
    },
    search() {
      this.getData(this);
    },
    cleardata() {
      this.pageNo = 1;
      this.loading = false;
      this.reportList = [];
      this.totalpage = 0;
      this.getData(this);
    },
    //标签切换
    onTabChange(item, index, prevIndex) {
      if (this.isActive === "C") {
        if (item.label == "待续期") {
          this.renewalStatus = 1;
        } else if (item.label == "已续期") {
          this.renewalStatus = 3;
        } else if (item.label == "已过期") {
          this.renewalStatus = 4;
        }
      } else if (this.isActive === "D") {
        if (item.label === "待续保") {
          this.renewalStatus = 1;
        } else if (item.label === "已续保") {
          this.renewalStatus = 3;
        } else {
          this.renewalStatus = 4;
        }
      }
      let sessionData = {
        isActive: this.isActive,
        renewalStatus: this.renewalStatus
      };
      sessionStorage.setItem("sessionData", JSON.stringify(sessionData));
      this.listpageNo = 1;
      this.reportList = [];
      // this.listFinished = false
      this.loadflag = false;
      if (this.reportList.length !== 0) {
        this.$refs.scrollView.finishLoadMore();
      }
      this.getData();
    },
    selectRisk(mg) {
      if (mg === "long") {
        this.isActive = "C";
        this.renewalStatus = this.current;
        let sessionData = {
          isActive: this.isActive,
          renewalStatus: this.current
        };
        sessionStorage.setItem("sessionData", JSON.stringify(sessionData));
        this.getData();
      } else {
        this.isActive = "D";
        this.renewalStatus = this.shortCurrent;
        let sessionData = {
          isActive: this.isActive,
          renewalStatus: this.shortCurrent
        };
        sessionStorage.setItem("sessionData", JSON.stringify(sessionData));
        this.getData();
      }
    }
    // forwardBtn(){
    //   alert('转发')
    // }
  }
};
</script>
<style lang="stylus" scoped>
/deep/ .icdoling .md-icon {
  width: 1.42rem;
  height: 1.42rem;
  line-height: 1.42rem;
}

.mainbody {
  background-color: #F7F6FB;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.product {
  // height: 480px;
  width: 960px;
  background: #fff;
  // border-top: 20px solid #F7F6FB;
  margin: 0px auto;
  margin-top: 0.24rem;
  border-radius: 0.16rem;
}

.product-lf {
  padding-top: 30px;
  // padding: 30px 0;
  width: 100%;
  height: 100%;
  // left: 30px;
  position: relative;
}

.product-rg-top {
  display: flex;
  align-items: center;
  padding: 0 30px;
}

.product-rg-top span {
  height: 70px;
  line-height: 0.7rem;
  font-family: 'PingFang-SC-Medium';
}

.product-bom {
  // padding: 20px 30px;
  padding: 0.1rem 0.3rem 0rem 0.3rem;
}

.product-rg-bom span {
  font-family: 'PingFang-SC-Regular';
  line-height: 70px;
  font-size: 0.32rem;
  color: #535353;
  font-weight: 400;
}

.search {
  width: 100vw;
  line-height: 10vh;
  display: flex;
  align-items: center;
}

.searchlf {
  width: 80%;
  height: 100%;
}

.searchlf /deep/ .md-field-item-placeholder {
  font-size: 0.33rem;
  font-family: PingFang-SC-Medium;
  font-weight: 500;
  color: #989898 !important;
}

.searchfr {
  width: 10%;
}

.list {
  height: 100%;
}

.clearfix:after, .clearfix:before {
  content: '';
  display: table;
}

.clearfix:after {
  clear: both;
}

// .clearfix {
// @css { * }zoom: 1;
// }
.p-name {
  font-size: 0.37rem;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  color: #373737;
  display: inline-block;
}

.xq-ymont {
  height: 50px !important;
  line-height: 50px !important;
  position: absolute;
  right: 0.3rem;
  width: 150px;
  text-align: center;
  font-size: 0.37rem;
  font-family: PingFang-SC-Medium;
  font-weight: 500;
  color: #FF7326;
}

.xq-qc {
  display: inline-block;
  width: 49%;
}

.jf-rq {
  font-size: 35px !important;
  color: #808080;
  position: absolute;
  right: 80px;
  color: #077CC8;
  font-weight: 10 !important;
}

.tb-name {
  display: inline-block;
  width: 49%;
}

.paramValue {
  display: inline-block;
  width: 49%;
  text-align: right;
}

.bb-name {
  display: inline-block;
  width: 49%;
  margin-left: 1%;
}

.jf-status {
  width: 49%;
  margin-left: 1%;
}

.yjf-rq {
  width: 49%;
  margin-left: 1%;
}

.sx-rq {
  width: 49%;
  display: inline-flex;
}

.km-rq {
}

/deep/ .md-input-item-input {
  font-size: 0.33rem;
  font-family: PingFang-SC-Medium;
  font-weight: 500;
  color: #989898 !important;
}

/deep/ .md-input-item-input::-webkit-input-placeholder {
  font-size: 0.33rem;
  font-family: PingFang-SC-Medium;
  font-weight: 500;
  color: #989898 !important;
}

.tabBar {
  // position: fixed;
  // top: 0;
  width: 100%;
  background: #ffffff;
}

.selectInsurance {
  text-align: center;
  padding: 0.3rem 0;
  background: #fff;
}

.longRisk, .shortRisk {
  width: 2.26rem;
  height: 0.8rem;
  line-height: 0.7rem;
  border: 1px solid #FF7326;
  display: inline-block;
  font-size: 0.4rem;
  font-family: PingFang-SC-Medium;
  font-weight: 500;
  color: #333;
}

.longRisk {
  border-top-left-radius: 0.13rem;
  border-bottom-left-radius: 0.13rem;
}

.shortRisk {
  border-left: none;
  border-top-right-radius: 0.13rem;
  border-bottom-right-radius: 0.13rem;
}

.isActive {
  background: #FF7326;
  color: #fff;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
}

.renewalSituation {
  width: 100%;
  // padding: 0 30px;
  border-top: 0.01rem solid #d1d1d1;
  padding: 0.1rem 0.3rem 0 0.3rem;
  margin-top: 0.2rem;
}

.forwarding {
  height: 0.67rem;
  display: inline-block;
  line-height: 0.7rem;
  font-size: 0.32rem;
  background: #FF7E3A;
  position: absolute;
  right: 0.5rem;
  width: 2.27rem;
  text-align: center;
  color: #FFFFFF;
  border-radius: 0.33rem;
  font-weight: 500;
  font-family: PingFang-SC-Medium;
}

/deep/ .md-tab-bar-item {
  font-size: 0.4rem;
  font-family: PingFang-SC-Medium;
  font-weight: 500;
  color: #333333;
}

/deep/ .md-tab-bar-item.is-active {
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  color: #FF7326;
}

/deep/ .md-tab-bar-ink {
  width: 0.63rem !important;
  left: 1.25rem;
}

.renewalEndTime {
  color: #FF7E3A;
  font-size: 0.37rem;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  line-height: 0.7rem;
  display: inline-block;
  margin-bottom: 0.15rem;
}

.noBottom {
  margin: 0;
}

.nodata {
  padding-top: 30%;
  text-align: center;

  .kongimg {
    width: 4rem;
    height: 4rem;
  }
}
</style>
